<template>
  <div class="profile-page">
    <!-- <div class="title">Профиль</div> -->
    <div class="box">
      <div class="sidebar">
        <div class="profile">
          <img v-if="user" :alt="user.name" :src="url + user.avatar" />
          <div class="name">
            {{ user.name }}<span>#{{ user.id }}</span>
          </div>
        </div>

        <div class="menu">
          <router-link to="/profile/bonuses" class="bonuses">
            <img alt="Бонусы" src="@/assets/icons/profile/bonuses.svg" />
            Бонусы
          </router-link>

          <router-link to="/profile/ranks">
            <img alt="Игры" src="@/assets/icons/star.svg" />
            Ранги
          </router-link>

          <router-link to="/profile/games">
            <img alt="Игры" src="@/assets/icons/profile/games.svg" />
            Игры
          </router-link>
          <router-link to="/profile/referals">
            <img
              alt="Партнерская программа"
              src="@/assets/icons/profile/refferals.svg"
            />
            Партнерская программа
          </router-link>
          <router-link to="/profile/payments">
            <img alt="Платежи" src="@/assets/icons/profile/payments.svg" />
            Платежи
          </router-link>
          <router-link to="/profile/settings">
            <img alt="Настройки" src="@/assets/icons/profile/settings.svg" />
            Настройки
          </router-link>
          <router-link to="/profile/security">
            <img alt="Безопасность" src="@/assets/icons/profile/settings.svg" />
            Безопасность
          </router-link>
          <router-link to="/logout">
            <img alt="Выйти" src="@/assets/icons/profile/logout.svg" />
            Выйти
          </router-link>
        </div>
      </div>
      <div class="content">
        <transition name="fade">
          <router-view />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: mapState("user", ["user"]),
  data() {
    return {
      url: this.CDN_URL,
    };
  },
};
</script>

<style lang="scss" scoped>
.profile-page {
  margin-bottom: 25px;
  .title {
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 22px;
  }
  .box {
    display: flex;
    .sidebar {
      width: 320px;
      margin-right: 15px;
      .profile {
        background-color: white;
        border-radius: 6px;
        padding: 30px;
        text-align: center;
        margin-bottom: 15px;
        span {
          color: #a3afc9;
        }
        img {
          width: 260px;
          height: 260px;
          object-fit: contain;
          margin-bottom: 25px;
          border-radius: 14px;
        }
        .name {
          font-weight: 500;
          font-size: 18px;
        }
      }
      .menu {
        a {
          display: flex;
          height: 60px;
          border-radius: 6px;
          margin-bottom: 15px;
          align-items: center;
          padding: 0 20px;
          background-color: white;
          color: #a3a8ca;
          //   font-weight: 500;
          font-size: 15px;
          img {
            margin-right: 10px;
            width: 30px;
            height: 20px;
          }
          &.bonuses {
            color: white;
            background-color: #57c400;
            img {
              filter: brightness(2);
            }
          }
          &.router-link-active {
            color: white;
            background-color: #0085ff;
            pointer-events: none;
            img {
              filter: brightness(2);
            }
          }
          transition: 200ms ease;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
    .content {
      width: calc(100% - 320px - 15px);
      border-radius: 6px;
      background-color: white;
      padding: 30px;
      margin-bottom: 15px;
    }

    @media (max-width: 768px) {
      flex-wrap: wrap;
      .sidebar {
        width: 100%;
        margin-right: 0;
        .menu {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          a {
            width: calc(100% / 2 - 5px);
          }
        }
      }
      .content {
        width: 100%;
      }
    }
  }
}
</style>
